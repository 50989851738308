.sBro {
  padding: 0 !important;
  @include media-breakpoint-up(lg) {
    --sPT: #{rem(50)};
    --sPB: #{rem(50)};
  }

  position: relative;
  z-index: 1;
  &__bg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(xxl) {
        object-position: 50% 40%;
      }
    }
    &:after {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        #141111 5.22%,
        rgba(20, 17, 17, 0) 99.27%
      );
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    min-height: calc(80vh - var(--header-h));
    @include media-breakpoint-up(xxl) {
      min-height: calc(#{rem(700)} - var(--header-h));
    }
    padding: var(--sPT) calc(#{$container-padding-x} / 2) var(--sPB);

    //@include media-breakpoint-up(xxl){
    //  padding-left: 30px;
    //}
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: rem(90);
      margin-right: rem(18);
      @include media-breakpoint-up(sm) {
        width: rem(115);
        margin-right: rem(24);
      }
    }

    margin-bottom: rem(32);
  }
  &__title {
    margin-bottom: 0;
    font-family: $mono;
    font-weight: 700;

    font-size: rem(40);
    @include media-breakpoint-up(sm) {
      font-size: rem(64);
    }
    line-height: calc(77 / 64);

    color: $primary;
  }
  &__descr {
    max-width: rem(909);
    margin: 0 auto;

    font-size: rem(20);
    @include media-breakpoint-up(sm) {
      font-size: rem(24);
    }
    color: #fff7f7;
  }
}
