.header {
  transition: box-shadow 0.3s ease-in-out;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  padding: rem(10) 0;
  background: $body-bg;

  width: 100%;
  @include media-breakpoint-up(xl) {
    width: 100vw;
  }

  &.fixed {
    box-shadow: 0px 0px 12px 0px rgba($black, 0.5);
  }

  &__row {
    --bs-gutter-x: #{rem(24)};
    --bs-gutter-y: #{rem(12)};
  }
  &__logo {
    display: block;
    width: rem(69);
    img {
      width: 100%;
    }
  }
  &__cart {
    width: rem(24);
    height: rem(24);
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      pointer-events: none;
      position: absolute;
      bottom: calc(100% - 8px);
      left: calc(100% - 6px);
      width: 16px;
      height: 16px;
      padding: 4px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: rem(10);

      background: $primary;
      color: $white;
    }
    &.active {
      &:before {
        content: attr(data-count);
      }
    }

    & > * {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
    &:hover > * {
      path {
        stroke: $primary !important;
      }
    }

    //&:before{
    //  content: '';
    //  position: absolute;
    //  z-index: -1;
    //  top: 50%;
    //  left: 50%;
    //  transform: translateX(-50%) translateY(-50%);
    //  width: rem(32);
    //  height: rem(32);
    //}
  }
  &__burger {
    width: rem(40);
    height: rem(40);
    position: relative;

    span:before,
    span:after,
    span {
      @include mt();
      content: "";
      width: rem(32);
      height: rem(4);
      border-radius: rem(2);
      background-color: $white;
      position: absolute;
    }

    span {
      top: 50%;
      left: calc(50%);
      transform: translateX(-50%) translateY(-50%);
    }

    span:before {
      top: -9px;
      left: 0px;
    }
    span:after {
      left: 0px;
      top: 9px;
    }

    &.active {
      span {
        background-color: transparent;
      }

      span:before {
        transform: rotate(-45deg);
        top: 0;
        left: 0;
      }
      span:after {
        transform: rotate(45deg);
        top: 0;
        left: 0;
      }
    }
  }

  .menu {
    &__row {
      --bs-gutter-x: #{rem(16)};
      @include media-breakpoint-up(xxl) {
        --bs-gutter-x: #{rem(20)};
      }
      --bs-gutter-y: #{rem(12)};
    }
    &__link {
      display: flex;
      align-items: center;

      font-weight: 500;
      font-size: rem(14);
      line-height: calc(17 / 14);
      color: $white;

      //?

      &:hover {
        color: $primary;

        --def-img-o: 0;
        --active-img-o: 1;
      }

      &.active {
        transition: all 0s;
        color: $primary;

        --def-img-o: 0;
        --active-img-o: 1;
      }
    }

    &__link-img {
      width: rem(16);
      height: rem(16);
      margin-right: rem(4);

      position: relative;
      img {
        @include mt();
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;

        &:first-child {
          opacity: var(--def-img-o, 1);
        }
        &:last-child {
          opacity: var(--active-img-o, 0);
        }
      }
    }
  }

  .search {
    position: relative;
    z-index: 1;

    &__inp-wrap {
      position: relative;
      z-index: 1;
    }
    &__input {
      width: rem(280);
      height: rem(36);
      padding-right: rem(48);

      border-radius: rem(60);
      font-size: 12px;
      line-height: calc(15 / 12);
      background: #2d2a2a;
      border: none;
    }
    &__inp-icon {
      @include mt();
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: rem(36);
      height: rem(36);
      border-radius: 50%;
      padding: rem(8);

      display: flex;
      align-items: center;
      justify-content: center;

      & > * {
        max-width: 100%;
      }

      background: rgba($black, 0.1);
      path {
        stroke: #7a7a7a !important;
      }

      &:hover {
        background: $primary;
        path {
          stroke: white !important;
        }
      }
    }

    //

    &__dd {
      position: absolute;
      top: 100%;
      right: 0;
      width: rem(681);
      padding-top: rem(10);
      max-height: calc(100vh - var(--header-h));
      overflow-y: auto;
    }
    &__dd-inner {
      background: #0b0b0b;
      border-radius: rem(12);
      padding: rem(12) 0;
    }
    &__dd-item {
      @include mt();
      padding: rem(8) rem(16);
      display: block;
      color: $white;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
    &__dd-dots {
      padding: rem(4) rem(16);
    }
    &__dd-row {
      --bs-gutter-x: #{rem(16)};
    }
    &__item-img {
      width: rem(48);
      height: rem(48);
      border-radius: 8px;
      overflow: hidden;

      img {
        object-fit: cover;
      }
    }

    &__item-name {
      @include ell(2);
      line-height: calc(20 / 16);
    }

    &__dd-link {
      display: inline-block;
      padding: rem(8) rem(16);
      font-weight: 500;
      line-height: 1.125;

      &.muted {
        color: $gray-600;
      }
    }
  }

  //currency
  .currency {
    position: relative;
    z-index: 1;

    &__row {
      --bs-gutter-x: #{rem(22)};
      & > * {
        &:first-child {
          border-right: 1px solid #3c3c3c;
        }
      }
    }
    &__ball {
      margin: rem(2) 0;
      width: rem(28);
      height: rem(28);
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &__currency {
      font-weight: 500;
      font-size: rem(13);
      line-height: calc(16 / 13);
    }

    //
    &:hover {
      --dd-shift: 0px;
      --dd-pe: auto;
      --dd-opacity: 1;
    }
    &__dd {
      @include mt();
      transform: translateX(var(--dd-shift, 5px))
        translateY(var(--dd-shift, -5px));
      pointer-events: var(--dd-pe, none);
      opacity: var(--dd-opacity, 0);

      position: absolute;
      top: 100%;
      right: 0;
      width: rem(300);
      padding-top: rem(10);
    }
    &__dd-inner {
      background: #0b0b0b;
      border-radius: rem(12);
      padding: rem(12) rem(16);
    }
    &__dd-title {
      font-weight: 600;
      font-size: rem(12);
      line-height: calc(22 / 12);
      letter-spacing: rem(-0.128);
    }
    &__box {
      margin-bottom: rem(12);
      background: rgba(35, 55, 55, 0.1);
      border-radius: rem(8);
      padding: rem(4);
    }
    &__b-row {
      --bs-gutter-x: #{rem(4)};
    }
    &__btn {
      @include mt();
      @extend .btn;
      @extend .btn-primary;
      border: none !important;
      padding: rem(4) rem(12);
      min-height: rem(32);

      display: flex;
      align-items: center;
      justify-content: center;

      background: transparent !important;

      img {
        width: rem(16);
        margin-right: rem(4);
      }

      font-size: 14px;
      letter-spacing: rem(-0.128);
      color: $secondary !important;
      &:hover {
        color: $white !important;
        background: rgba($primary, 0.1) !important;
      }
      &.active {
        color: $white !important;
        background: $primary !important;
      }
    }
  }
}
