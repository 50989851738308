.sCart {
  @include media-breakpoint-up(sm) {
    --sPT: #{rem(50)};
    --sPB: #{rem(50)};
  }
  @include media-breakpoint-up(xxl) {
    --sPT: #{rem(50)};
    --sPB: #{rem(50)};
  }
  --sTPB: #{rem(0)};

  &__row {
    --bs-gutter-x: #{rem(24)};
    @include media-breakpoint-up(xl) {
      --bs-gutter-x: #{rem(32)};
    }

    --bs-gutter-y: #{rem(32)};
  }
  &__col {
    --left-col-w: 58.3%;

    &--left {
      @include media-breakpoint-up(xl) {
        width: var(--left-col-w) !important;
      }
    }
    &--right {
      @include media-breakpoint-up(xl) {
        width: calc(100% - var(--left-col-w)) !important;
      }
    }
  }
  //left
  &__title-row {
    margin-bottom: rem(24);
  }

  &__amount {
    font-weight: 500;
    font-size: rem(20);
    line-height: 1;
  }

  //
  &__item {
    position: relative;

    margin-bottom: rem(24);
    &:last-child {
      margin-bottom: 0;
    }

    background: #0b0b0b;
    border-radius: rem(15);
    padding: rem(16);
    @include media-breakpoint-up(sm) {
      padding: rem(16) rem(16+32+24) rem(16) rem(16);
    }
  }
  &__i-row {
    --bs-gutter-x: #{rem(24)};
    --bs-gutter-y: #{rem(18)};
  }
  &__i-col {
    --left-col-w: #{rem(128)};

    &--left {
      @include media-breakpoint-up(sm) {
        width: calc(var(--left-col-w) + var(--bs-gutter-x)) !important;
      }
    }
    &--right {
      @include media-breakpoint-up(sm) {
        width: calc(100% - var(--left-col-w) - var(--bs-gutter-x)) !important;
      }
    }
  }
  &__img {
    @extend .accept-ratio-frame;
    padding-top: 100%;
    border-radius: rem(8);
  }
  &__name-box {
    display: flex;
    align-items: center;

    margin-bottom: rem(24);
  }
  &__name {
    color: $white;
    font-weight: 500;
    font-size: rem(20);
    line-height: 1.2;
    margin-right: rem(16);
  }
  &__discount {
    margin-left: auto;
    @include media-breakpoint-up(sm) {
      margin-left: 0;
    }
    padding: rem(5) rem(4);
    border-radius: rem(5);
    flex-shrink: 0;
    background: rgba(240, 105, 29, 0.7);

    font-family: $mono;
    font-size: rem(12);
    line-height: 1;
    color: $black;
  }
  &__price-row {
    --bs-gutter-x: #{rem(23)};
  }
  &__controll-box {
    display: flex;
    align-items: center;
  }
  &__btn {
    @extend .btn;
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 50%;

    background: #212121;
    &:hover {
      background: $primary;
    }
    position: relative;
    z-index: 1;
    border: none !important;

    &.disabled {
      pointer-events: none !important;
      background: #212121 !important;
      opacity: 0.7;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);

      background: $white;
      border-radius: 2px;
    }

    &:before {
      width: 10px;
      height: 2px;
    }
    &:after {
      height: 10px;
      width: 2px;
    }

    &--minus {
      &:after {
        content: none;
      }
    }
  }
  &__item-amount {
    padding: 0 rem(8);
    min-width: rem(40);
    text-align: center;
    font-weight: 600;
    font-size: rem(22);
    color: #565656;
  }
  &__old-price {
    margin-bottom: rem(4);
    font-weight: 500;

    font-size: rem(14);
    line-height: calc(17 / 14);
    text-decoration-line: line-through;
    color: #565656;
  }
  &__price {
    font-weight: 500;
    line-height: calc(20 / 16);
    color: $primary;
  }
  &__trash {
    cursor: pointer;
    @include media-breakpoint-up(sm) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: rem(32);
    }

    width: rem(24);
    height: rem(24);

    &:hover {
      path {
        stroke: $primary !important;
      }
    }
  }

  &__summary {
    background: #0b0b0b;
    border-radius: rem(12);

    padding: rem(24) rem(16);
    @include media-breakpoint-up(sm) {
      padding: rem(32) rem(24);
    }
    @include media-breakpoint-up(xxl) {
      padding: rem(29) rem(32) rem(32) rem(32);
      margin-top: rem(8);
    }
  }
  &__s-title {
    @extend .h3;
    margin-bottom: rem(18);
    line-height: 1;
  }
  &__s-descr {
    font-size: rem(14);
    color: #b7b7b7;

    margin-bottom: rem(24);
    @include media-breakpoint-up(sm) {
      margin-bottom: rem(38);
    }
  }
  &__s-total-wrap {
    display: flex;
    align-items: baseline;

    margin-bottom: rem(23);
  }
  &__s-total-txt {
    font-weight: 600;
    line-height: 1.125;
    color: $white;
    margin-right: rem(16);
  }
  &__s-price {
    font-weight: 600;
    font-size: rem(40);
    line-height: 1.125;
  }
  &__s-btn {
    @extend .btn;
    @extend .btn-primary;
    text-transform: uppercase;

    min-width: 100%;
    @include media-breakpoint-up(sm) {
      min-width: rem(185);
    }
  }
}
