:root {
	--animate-delay: 1.2s;

	--bs-body-font-size: 13px;
	@media (min-width: 379px) {
		--bs-body-font-size: 14px;
	}
	@include media-breakpoint-up(md){
		--bs-body-font-size: 15px;
	}
	@include media-breakpoint-up(xxl){
		--bs-body-font-size: #{$em-base};
	}


	--slide-w: 50vw;
	--sPT: #{rem(45)};
	--sPB: #{rem(45)};
	--sTPB: #{rem(30)};
	@include media-breakpoint-up(xxl){
		--sPT: #{rem(90)};
		--sPB: #{rem(90)};
		--sTPB: #{rem(40)};
	}

	--fancybox-content-color: var(--bs-body-color);
		--swiperArrowSize: #{rem(68)};
		--swiperArrowPosition: calc(-1 * var(--swiperArrowSize) / 2);
		--swiperBorderColorDef: var(--bs-primary);
		--swiperColorDef: var(--bs-primary);
		--swiperBgDef: #ffffff;
	
		--swiperBorderColorDefHover: var(--bs-primary);
		--swiperColorDefHover: var(--bs-primary);
		--swiperBgDefHover: #ffffff;


	--fancybox-bg: rgba(24, 24, 27, .77);
}
