.pixel-perfect {
  top: 0;
  display: block;
  @include a-cent(x);
  pointer-events: none;
  z-index: 1000;
  overflow: hidden;

  img {
    display: block;
    margin: 0 auto;
    width: auto;
    height: 100%;
    object-position: center 0;
  }

  opacity: 0;
  //opacity: 0.7;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

body,
html {
  font-size: var(--bs-body-font-size);
}
html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(33, 36, 41, 0);
  // margin-right: calc(-1 * (100vw - 100%));
}

body {
  &.fixed,
  &.fancybox-active {
    .header {
      padding-right: var(--spacing-end);
    }
    margin-right: var(--spacing-end);
  }
  // min-width: 575px;
  // -webkit-font-smoothing: antialiased;
  // position: relative;
  // overflow-x: hidden;

  &.loaded_hiding {
    @include mt;

    opacity: 0;
    pointer-events: none;

    &::before {
      display: none;
    }
  }

  &.loaded {
    pointer-events: visible;
    opacity: 1;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include mt;
    // background-color: $body-color;
    background-color: rgba($body-color, 0.8);
    backdrop-filter: blur(5px);
    opacity: 0;
    z-index: -1;
    // left: 200px;
    left: 0;
    // opacity: 1;
    // z-index: 1;
    top: 0;
  }

  &.fancybox-active {
    overflow: hidden;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  &.fixed {
    overflow: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;

    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    /* Other browsers */
    overscroll-behavior: none;

    //&::before {
    //	z-index: 999;
    //	opacity: 0.76;
    //}
  }
}

.main-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &:not(&--catalog) {
    overflow: hidden;
  }
}
main {
  flex-grow: 1;
}
// .main-wrapper__inner{
// 	// overflow-x: hidden;
// 	display: flex;
// 	flex-direction: column;
// 	min-width: 0;
// 	min-height: 100vh;
// 	flex-grow: 1;
// }

.section {
  @include bgs;
  padding-top: var(--sPT);
  padding-bottom: var(--sPB);
}

.section-title {
  margin-bottom: var(--sTPB);

  & > *:last-child {
    margin-bottom: 0 !important;
  }
}

.mono {
  font-family: $font-family-code;
}

.small {
  font-size: $small;
}

.tdn {
  text-decoration: none !important;
}

.ttu {
  text-transform: uppercase;
}

.tdu {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.fw-300 {
  font-weight: 300;
}

.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}

.fw-900 {
  font-weight: 900;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-100 {
  font-weight: 100;
}

.row--sm {
  --bg-gutter-x: #{rem(20)};
}

.slick-slide img {
  display: inline;
}

.image-source-link {
  color: #98c3d1;
}

.slick-slide {
  outline: 0 !important;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  &.l {
    left: 0;
    svg {
      transform: transform(rotateY(180deg));
    }
  }
  &.r {
    right: 0;
  }
  svg {
    width: 17px;
    height: auto;
  }
}

.strong {
  font-weight: bold;
}

ul {
  padding-left: 0;
}

.res-i {
  max-width: 100%;
  // width: auto;
  height: auto;
}

.img-bg {
  @include img-bg();
}

.bgs {
  @include bgs();
}

.table {
  display: table;
}

.tr {
  display: table-row;
}

.td {
  display: table-cell;
}

.tabs__btn-accordion {
  // display: none;
  width: 100%;
}
.tabs {
  &__btn {
    &:hover {
      cursor: pointer;
    }
  }

  &__content {
    // opacity: 0;
    // @include mt;
    &.active {
      display: block;
      // opacity: 1;
    }

    display: none;
  }
}

.invisible {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.control-wrap {
  position: relative;
}

.swiper-button-hand {
  width: var(--swiperArrowSize);
  height: var(--swiperArrowSize);
  border-radius: 50%;
  background-color: var(--swiperBgDef);
  border: 2px solid var(--swiperBorderColorDef);
  color: var(--swiperColorDef);
  @include bgs;
  z-index: 300;
  outline: none !important;
  // margin-bottom: 0;
  background-image: none;
  margin: 0;
  @include a-cent(y);
  box-shadow: 0 15px 60px rgba(0, 0, 0, 0.1);
  @include mt;
  user-select: none;

  &:hover {
    background-color: var(--swiperBgDefHover);
    border-color: var(--swiperBorderColorDefHover);
    color: var(--swiperColorDefHover);
  }

  .icon {
    font-size: rem(12);
  }

  &::after {
    display: none;
  }
  &-next {
    right: var(--swiperArrowPosition);
  }

  &-prev {
    left: var(--swiperArrowPosition);
  }
}

.swiper-pagination {
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &-bullet {
    cursor: pointer;
    @include mt;
    outline: none !important;

    margin: rem(8) rem(12);
    @include media-breakpoint-up(md) {
      margin: rem(4) rem(17);
    }

    opacity: 1;
    width: 6px;
    height: 6px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      width: rem(24);
      height: rem(24);
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    box-shadow: 0 0 0 2px transparent;
    background-color: $white;
    &-active {
      box-shadow: 0 0 0 2px $white;
      background-color: transparent;
    }
  }
}

@include media-breakpoint-up(lg) {
  .wow {
    visibility: hidden;
  }
}
picture {
  display: block;
  img {
    @extend .res-i;
  }
}

img.object-fit-js {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
}

img.img-bg,
.object-fit-js img {
  object-fit: cover;
  object-position: top;
}

a {
  @include mt;
}

img.img-bg,
.picture-bg {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .object-fit-js {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.bg-wrap {
  position: relative;
  z-index: 2;
}

// .pagination-wrapper li

.browsehappy {
  position: absolute;
  // position: fixed;
  width: 100%;
  z-index: 2000;
  background: #000;
  min-height: 100px;
  font-size: 10px;
  color: #ffffff;
  top: 0;
  padding-top: 30px;
}

iframe {
  border: none;
}

// *::selection {
// 	color: #fff;
// 	/* Цвет текста */
// 	background: var(--bs-primary) ;
// 	/* Цвет фона */
// }

.custom-scroll {
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  --bs-primary: #dedede;

  & {
    scrollbar-width: auto;
    scrollbar-color: var(--bs-primary) transparent;
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--bs-primary);
    border-radius: 16px;
    border: 0px solid transparent;
  }
}

::-webkit-calendar-picker-indicator {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='15' viewBox='0 0 24 24'><path fill='#{$primary}' d='M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z'/></svg>");
}

.btn,
.form-control,
.form-select {
  box-shadow: none !important;
}

.fancybox__content :focus:not(.carousel__button.is-close) {
  outline: none !important;
}

.form-switch,
.form-check-input,
.form-check {
  cursor: pointer;
}

.img-wrap-center {
  @include a-cent;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
  img.object-fit-js {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

//
.accept-ratio-frame {
  display: block;
  width: 100%;
  height: 0;
  position: relative;

  overflow: hidden;

  img {
    @include mt();
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}
.accept-ratio-frame-nh {
  display: block;
  width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;

  img {
    @include mt();
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

//
.counter {
  counter-reset: num;

  &__item {
    position: relative;
    &::before {
      content: counter(num);
      counter-increment: num;
    }
  }
}

//
.main-wrapper {
  padding-top: var(--header-h);
}

//
.btn-primary {
  color: $white !important;
}
//
.swiper-btn {
  cursor: pointer;
  @include mt();

  width: rem(48);
  height: rem(48);
  padding: rem(10);
  border-radius: 50%;
  border: 1.5px solid #1f1f1f;
  background: #0b0b0b;
  &:hover {
    background: $black;
    box-shadow: 2px 2px 12px -4px rgba($black, 0.25);
  }

  display: flex;
  align-items: center;
  justify-content: center;
}

//
.swiper-tp-btn {
  @include mt();
  cursor: pointer;
  width: rem(40);
  height: rem(40);
  border-radius: 50%;

  background: transparent;
  &:hover {
    background: $body-bg;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(2);

  & > * {
    width: 100%;
    height: 100%;
  }
}

body {
  @extend .custom-scroll;
}

//
.custom-checkbox {
  display: flex !important;
  align-items: center;
  cursor: pointer;

  input:checked + .c-square {
    border-color: $primary;

    &:before {
      opacity: 1;
    }
  }
  input:checked + .c-square:not(.c-square--round) {
    background: $primary;
  }

  .c-square {
    @include mt();
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border: 1px solid $white;
    position: relative;
    margin-right: 8px;

    border-radius: 3px;

    &:before {
      @include mt();
      opacity: 0;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

    &--round {
      border-radius: 50%;
      &:before {
        background: $primary;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        border-radius: inherit;
      }
    }

    &:not(&--round) {
      &:before {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        background-image: url(../img/svg/check-select.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  .c-txt {
    font-size: rem(14);
    line-height: 1.2;

    .modify {
      color: rgba($white, 0.5);
    }
  }

  &:hover {
    .c-square {
      border-color: $primary;
    }
  }
}

//
.required-symb {
  color: $danger;
  font-weight: 700;
  margin-left: 3px;
}

.form-select {
  cursor: pointer;
  //background-image: url(../img/svg/check-select.svg) !important;
  //background-position: 50% calc(100% - 16px) !important;
  //background-size: 16px!important;
  //background-repeat: no-repeat!important;
}
