.sReview {
  overflow: hidden;
  @include media-breakpoint-up(xxl) {
    --sPT: #{rem(50px)};
    --sPB: #{rem(50)};
    --sTPB: #{rem(40)};
  }

  &__slider {
    overflow: visible;
    @include media-breakpoint-between(sm, md) {
      width: calc(
        #{map-get($container-max-widths, "sm")} - #{$container-padding-x}
      ) !important;
    }
    @include media-breakpoint-between(md, lg) {
      width: calc(
        #{map-get($container-max-widths, "md")} - #{$container-padding-x}
      );
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    position: relative;
    z-index: 1;
    &:before,
    &:after {
      @include media-breakpoint-up(sm) {
        content: "";
      }
      position: absolute;
      z-index: 10;
      top: -25px;
      width: calc((100vw - 100%) / 2 + 10px);
      height: calc(100% + 50px);
      background: $body-bg;
    }
    &:before {
      left: 100%;
    }
    &:after {
      right: 100%;
    }

    @include media-breakpoint-up(lg) {
      height: 496px;
      margin-bottom: rem(80);
    }

    .swiper-wrapper {
      order: 1;
      margin-bottom: 22px;
    }
    .swiper-slide {
      @include media-breakpoint-down(sm) {
        width: 80vw !important;
      }
      @include media-breakpoint-between(sm, md) {
        width: calc(
          #{map-get($container-max-widths, "sm")} - #{$container-padding-x}
        ) !important;
      }
      @include media-breakpoint-between(md, lg) {
        width: calc(
          #{map-get($container-max-widths, "md")} - #{$container-padding-x}
        );
      }

      height: auto;
      @include media-breakpoint-up(lg) {
        height: calc((100% - 32px) / 2) !important;
      }

      display: flex;
      flex-direction: column;
    }
    .swiper-pagination {
      @include media-breakpoint-down(sm) {
        display: none;
      }
      @include media-breakpoint-up(lg) {
        margin-right: 10px;
        width: auto;
        order: 9;
      }
    }
  }
  .swiper-tp-btn {
    &.prev {
      margin-right: 10px;
      order: 8;
    }
    &.next {
      order: 10;
    }
  }

  //
  &__item {
    flex-grow: 1;

    padding: rem(24) rem(16);
    @include media-breakpoint-up(sm) {
      padding: rem(24) rem(32);
    }
    background: #0b0b0b;
    border-radius: rem(12);

    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  &__top-row {
    margin-bottom: rem(12);
    @include media-breakpoint-up(lg) {
      margin-bottom: rem(3);
    }
  }
  &__date {
    font-weight: 500;
    font-family: $mono;
    color: #7b7b7b;
  }
  &__fb-img {
    width: rem(24);

    img {
      width: 100%;

      &.bad {
        transform: rotate(180deg);
      }
    }
  }
  &__txt {
    font-family: $mono;
    font-weight: 500;

    font-size: rem(17);
    @include media-breakpoint-up(sm) {
      font-size: rem(20);
    }
    color: $white;

    margin-bottom: rem(24);

    @include media-breakpoint-up(lg) {
      @include ell(3);
    }

    &--more-lines {
      @include media-breakpoint-up(lg) {
        @include ell(5);
      }
      padding-top: rem(10);
      margin-bottom: 0;
    }
  }
  &__bot-row {
    margin-top: auto;
    --bs-gutter-x: #{rem(16)};
    //--bs-gutter-y: #{rem(16)};
  }
  &__game-img {
    width: rem(46);
    height: rem(46);
    border-radius: rem(8);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__game-name {
    word-break: break-word;
    @include ell(2);
    color: $white;
  }
}
