.sProd {
  --sPT: #{rem(0)};
  background: #0b0b0b;

  --sTPB: #{rem(24)};
  @include media-breakpoint-up(sm) {
    --sTPB: #{rem(30)};
  }
  @include media-breakpoint-up(lg) {
    --sPT: #{rem(40)};
    background: #1e1e1e;
  }
  @include media-breakpoint-up(xl) {
    --sPT: #{rem(64)};
    --sPB: #{rem(52)};
    --sTPB: #{rem(22)};
  }

  overflow: hidden;
  position: relative;
  z-index: 1;

  &__bg {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1440px;
    width: 100%;
    height: 100%;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: rem(858);
      height: rem(439);

      opacity: 0.1;
      @include media-breakpoint-up(lg) {
        opacity: 1;
      }
    }
    &:before {
      left: calc(480 / 1440 * 100%);
      top: -196px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(240, 105, 29, 0.26) 0%,
        rgba(240, 105, 29, 0) 100%
      );
    }
    &:after {
      left: 25px;
      bottom: -57px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(240, 105, 29, 0.26) 0%,
        rgba(240, 105, 29, 0) 100%
      );
    }
  }
  &__row {
    //--bs-gutter-x: #{rem(0)};
    //--bs-gutter-y: #{rem(0)};
    //@include media-breakpoint-up(lg){
    //}
    --bs-gutter-x: #{rem(32)};
    --bs-gutter-y: #{rem(32)};
    @include media-breakpoint-up(xxl) {
      --bs-gutter-x: #{rem(56)};
      --bs-gutter-y: #{rem(40)};
    }
  }
  &__col {
    --left-col-w: 47.49%;

    &--left {
      @include media-breakpoint-up(xxl) {
        width: var(--left-col-w) !important;
      }
    }
    &--right {
      @include media-breakpoint-up(xxl) {
        width: calc(100% - var(--left-col-w)) !important;
      }
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
    }
  }
  &__slider {
    margin-top: rem(30);
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
    width: 100%;
    margin-bottom: 16px;
  }

  &__thumb-wrap {
    position: relative;
    z-index: 2;
  }
  &__slider-thumb {
    width: 100%;
    .swiper-slide {
      width: calc(100% / 3 - 16px * 2 / 3);

      &.swiper-slide-thumb-active {
        --thumb-border-col: #{$primary};
      }
    }
  }
  &__thumb-img {
    cursor: pointer;
    border: 1px solid var(--thumb-border-col, #{$black});

    @extend .accept-ratio-frame;
    //padding-top: calc(120/172 * 100%);
    height: rem(120);
    border-radius: rem(8);

    & > svg {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: rem(60);
      height: auto;
    }
  }
  &__thumb-btn {
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);

    &.prev {
      left: 0;
    }
    &.next {
      left: 100%;
    }
  }

  &__img {
    &--video {
      iframe {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
      }
    }

    @extend .accept-ratio-frame;

    border-radius: rem(8);

    height: rem(350);
    @include media-breakpoint-up(md) {
      height: rem(450);
    }
    @include media-breakpoint-up(xxl) {
      height: rem(548);
    }
  }
  &__single-img {
    @extend .accept-ratio-frame;

    padding-top: 100%;
    @include media-breakpoint-up(sm) {
      padding-top: 0;
      height: 45vh;
    }
    @include media-breakpoint-down(lg) {
      width: 100vw;
      left: calc((100% - 100vw) / 2);
    }
    @include media-breakpoint-up(lg) {
      padding-top: 100%;
      border-radius: rem(8);

      flex-grow: 1;
    }
    @include media-breakpoint-up(xxl) {
      flex-grow: 0;
    }
  }
  &__box {
    //padding: rem(32) rem(16);
    @include media-breakpoint-up(lg) {
      padding: rem(32) rem(24);
    }
    @include media-breakpoint-up(lg) {
      flex-grow: 1;
      border-radius: rem(12);
      background: #0b0b0b;
    }
    @include media-breakpoint-up(xl) {
      padding: rem(32) rem(48);
    }
    @include media-breakpoint-up(xxl) {
      padding: rem(23) rem(32) rem(36) rem(59);
    }
  }
  .section-title {
    font-family: $mono;
    h1 {
      word-break: break-word;
      line-height: 1.3;

      font-size: rem(24);
      @include media-breakpoint-up(sm) {
        font-size: rem(32);
      }
    }

    line-height: 1.3;
  }
  &__tags-row {
    --bs-gutter-x: #{rem(8)};
    --bs-gutter-y: #{rem(4)};

    margin-bottom: var(--sTPB);
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(32);
    }
  }
  &__price {
    font-weight: 600;
    font-size: rem(32);
    line-height: calc(39 / 32);
    margin-bottom: rem(24);
  }
  &__btn-row {
    --bs-gutter-x: #{rem(24)};
    --bs-gutter-y: #{rem(24)};

    margin-bottom: var(--sTPB);
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(32);
    }
  }
  &__btn {
    @extend .btn;
    @extend .btn-primary;
    text-transform: uppercase;

    &.disabled {
      background: $gray-600 !important;
      border-color: $gray-600 !important;
    }

    min-width: 100%;
    @include media-breakpoint-up(sm) {
      min-width: rem(200);
    }
    @include media-breakpoint-up(xxl) {
      min-width: rem(228);
      padding: rem(13) rem(24);
    }
  }
  &__cart {
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    @include mt();
    cursor: pointer;

    @include media-breakpoint-up(xxl) {
      margin-right: rem(8);
    }

    &:hover,
    &.active {
      path {
        stroke: $primary !important;
      }
    }
  }
  &__how-txt {
    @include mt();
    font-size: rem(20);

    &:hover {
      color: $primary;
      cursor: pointer;
    }
  }
  &__how-list {
    list-style: none;
    padding: 0;

    margin: 0 0 rem(-12);
    @include media-breakpoint-up(xxl) {
      margin: 0 0 rem(-12) rem(0);
    }

    @include media-breakpoint-up(sm) {
      columns: 2;
    }

    li {
      margin-bottom: rem(12);
      padding-left: rem(36);
      position: relative;

      &:nth-child(n + 4) {
        @include media-breakpoint-up(xxl) {
          margin-right: -14px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: rem(24);
        height: rem(24);
        border-radius: 50%;
        overflow: hidden;

        background-image: url(../../img/svg/check-icon.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      font-family: $mono;
      font-size: rem(14);
      line-height: calc(24 / 14);
    }
  }

  //options
  &__o-box {
    margin-bottom: rem(20);
  }
  &__o-title {
    font-size: rem(14);
    line-height: calc(24 / 14);

    &--radio {
      font-weight: 600;
      margin-bottom: rem(20);
    }

    &--checkbox {
      font-weight: 600;
      margin-bottom: rem(20);
    }

    &--select,
    &--textarea,
    &--text {
      margin-bottom: rem(4);
    }
  }
  //
  &__input {
    border-radius: rem(8);
    border: 1px solid #000 !important;
    background-color: #2d2a2a !important;
    padding: rem(12) rem(16);

    &--textarea {
      @extend .custom-scroll;
      resize: none;
      height: 120px;
    }

    &--select.placeholder {
      color: $gray-600;

      option {
        color: $white;
      }
    }
  }

  //radio
  &__o-items {
    @extend .custom-scroll;
    max-height: 50vh;
    overflow-y: auto;
    padding-right: rem(24);
  }
  &__o-item {
    margin-bottom: rem(12);

    &--checkbox {
      margin-bottom: rem(12);
    }
  }
  &__alert {
    font-weight: 700;
    line-height: 1.2;
    font-size: rem(14);
    color: $warning;
    //margin-bottom: rem(20);
    margin: rem(10) 0;
    padding: rem(4) rem(12);
    border-radius: 4px;
    border: 1px solid $warning;
    background: rgba($warning, 0.1);
  }
}

.pcUnit {
  &__inp-box {
    margin-bottom: rem(24);
  }
  &__i-row {
    --bs-gutter-x: #{rem(12)};
    @include media-breakpoint-up(sm) {
      --bs-gutter-x: #{rem(24)};
    }
  }
  &__i-title {
    font-size: rem(14);
    line-height: calc(24 / 14);
    margin-bottom: rem(4);

    strong {
      color: $primary;
    }
  }
  &__i-input {
    padding: rem(12) rem(16);
    text-align: right;
    font-size: rem(14);
    line-height: calc(17 / 14);

    border-radius: rem(8);
    border: 1px solid $black !important;
    background: #2d2a2a !important;
  }

  //hide inp number
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  //s-box
  &__s-box {
    margin-bottom: rem(24);
  }
  &__strip {
    padding: rem(12) rem(16);
    border-radius: rem(4);
    background: #181515;
    margin-bottom: rem(8);

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__s-row {
    --bs-gutter-x: #{rem(8)};
  }
  &__s-txt {
    position: relative;
    top: 2px;
    color: $white;
    font-size: rem(14);
  }
}
