.sSave {
  @include media-breakpoint-up(xxl) {
    --sPT: #{rem(50)};
    --sPB: #{rem(50)};
    --sTPB: #{rem(24)};
  }

  &__box {
    @include media-breakpoint-up(xl) {
      max-width: rem(1008);
      margin: 0 auto;
    }
  }
  &__hashtag {
    font-weight: 600;
    line-height: calc(18 / 16);
    color: $primary;
    margin-bottom: rem(0);
  }
  .section-title {
    max-width: rem(870);
    margin-left: auto;
    margin-right: auto;

    h2 {
      margin-bottom: rem(16);
      @include media-breakpoint-up(md) {
        margin-bottom: rem(42);
      }
    }
    p {
      font-size: rem(20);
    }
  }
  &__img {
    @extend .accept-ratio-frame;
    height: rem(412);

    @include media-breakpoint-down(sm) {
      width: 100vw;
      margin-left: calc((100% - 100vw) / 2);
    }
    @include media-breakpoint-up(sm) {
      border-radius: rem(12);
    }
  }
}
