.sPolicy {
  &__container {
    @include media-breakpoint-up(lg) {
      max-width: calc(720px + #{$container-padding-x}) !important;
    }
  }
  h1,
  h2 {
    font-size: rem(24);
    @include media-breakpoint-up(sm) {
      font-size: rem(32);
    }
    margin-bottom: rem(24);
  }

  &__title {
    text-transform: uppercase;
  }
  &__box {
    margin-bottom: rem(48);
  }
}
