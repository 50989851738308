.sWhat {
  @include media-breakpoint-up(xxl) {
    --sPT: #{rem(50)};
    --sPB: #{rem(50)};
  }

  --sTPB: #{rem(30)};

  &__row {
    --bs-gutter-y: #{rem(40)};

    --bs-gutter-x: #{rem(40)};
    @include media-breakpoint-up(xl) {
      --bs-gutter-x: #{rem(80)};
    }
  }
  &__col {
    --left-col-w: 51.9%;
    &--left {
      @include media-breakpoint-up(xxl) {
        width: var(--left-col-w) !important;
      }
    }
    &--right {
      //padding-top: 98px;
      @include media-breakpoint-up(xxl) {
        width: calc(100% - var(--left-col-w)) !important;
      }
    }
  }
  &__img {
    @extend .accept-ratio-frame;

    height: 208px;
    @include media-breakpoint-up(sm) {
      height: rem(180);
    }
    @include media-breakpoint-up(xl) {
      height: rem(208);
    }

    @include media-breakpoint-down(sm) {
      width: 100vw;
      margin-left: calc((100% - 100vw) / 2);
    }
    @include media-breakpoint-up(sm) {
      margin-bottom: rem(20);
      border-radius: rem(12);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  .section-title {
    h2 {
      margin-bottom: rem(25);
    }

    p {
      font-size: rem(20);
    }
  }
  &__content {
    h4 {
      margin-bottom: rem(20);
    }
    ol {
      margin: 0;
      padding: 0;
      list-style: none;
      counter-reset: num;

      li {
        padding-left: rem(40);
        @include media-breakpoint-up(sm) {
          padding-left: rem(58);
        }
        position: relative;
        line-height: 1.7;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "0" counter(num);
          counter-increment: num;

          font-weight: 600;
          font-size: rem(24);
          line-height: 1.3;
        }

        margin-bottom: rem(16);
        @include media-breakpoint-up(md) {
          margin-bottom: rem(19);
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
