.sItems {
  @include media-breakpoint-up(xxl) {
    --sPT: #{rem(50)};
    --sPB: #{rem(50)};
  }

  --sTPB: #{rem(0)};

  --row-gutter-y: #{rem(18)};
  --row-gutter-x: #{rem(18)};
  @include media-breakpoint-up(sm) {
    --row-gutter-x: #{rem(24)};
    --row-gutter-y: #{rem(24)};
  }
  @include media-breakpoint-up(lg) {
    --row-gutter-x: #{rem(32)};
    --row-gutter-y: #{rem(32)};
  }
  &__top-row {
    position: relative;
    z-index: 20;
    --bs-gutter-x: var(--row-gutter-x);
    --bs-gutter-y: var(--row-gutter-y);

    margin-bottom: rem(48);
  }
  .section-title {
    @include media-breakpoint-up(xxl) {
      position: relative;
      top: 3px;
      left: 7px;
    }
    h1 {
      @extend .h2;
    }
  }

  &__row {
    --bs-gutter-x: var(--row-gutter-x);
    --bs-gutter-y: var(--row-gutter-y);

    margin-bottom: rem(24);
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(41);
    }
  }
  &__col {
    display: flex;
    flex-direction: column;
  }
  &__loading {
    font-weight: 500;
    font-size: rem(16);
    line-height: calc(20 / 16);

    color: $white;

    &:after {
      display: inline-block;
      text-align: left;
      width: 20px;
      margin-left: 4px;
      content: "...";
      animation: loading 1.2s linear 0s infinite normal;
    }
  }

  //
  &__nothing-found {
    @extend .h1;
    font-weight: 500;
    font-size: rem(56);
    line-height: calc(68 / 56);
    text-align: center;

    color: rgba($white, 0.5);
  }

  //
  &__input-wrap {
    position: relative;
    margin-bottom: rem(20);

    border-radius: rem(8);
    overflow: hidden;
  }
  &__input {
    font-size: rem(18);
    font-weight: 400;
    background: #2d2a2a !important;
    border: none !important;
    padding-right: rem(60);
  }
  &__search-btn {
    @extend .btn;
    @extend .btn-primary;
    top: 0;
    right: 0;
    position: absolute;
    width: rem(50);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(12);
    border-radius: rem(8);

    svg {
      width: auto;
      height: 100%;
    }
  }
}

@keyframes loading {
  0% {
    content: ".";
  }
  33.3% {
    content: "..";
  }
  66.6% {
    content: "...";
  }
  100% {
    content: "";
  }
}

.custom-select {
  &__control {
    cursor: pointer !important;
    background: #0b0b0b !important;
    border-radius: 4px !important;
    min-height: 40px !important;
    border: none !important;

    width: 100% !important;
    @include media-breakpoint-up(md) {
      width: rem(176) !important;
    }

    box-shadow: none !important;
    &:hover {
      box-shadow: 0 0 0 1px $primary !important;
    }
  }
  &__value {
    font-weight: 500 !important;
    font-size: rem(14) !important;
    line-height: 1.3 !important;
    color: $white !important;
  }
  &__input {
    pointer-events: none;
  }
  &__indicators-cont {
    svg {
      fill: $primary !important;
    }
  }
  &__separator {
    display: none;
  }

  &__menu {
    z-index: 20;
    background: #0b0b0b !important;
    border-radius: 4px !important;
  }
  &__menu-option {
    cursor: pointer !important;
    font-weight: 500 !important;
    font-size: rem(14) !important;
    line-height: 1.3 !important;
    background: #0b0b0b !important;

    &.active {
      color: $primary !important;
      background: $black !important;
    }
    &:hover {
      color: $primary !important;
    }
  }
}

.pagination {
  display: flex;
  gap: rem(4);
  align-items: center;
  justify-content: center;
  list-style: none;

  li {
    a {
      @include mt();
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      min-width: rem(24);
      height: rem(24);
      border-radius: rem(5);
      padding: rem(4);

      color: $white;
      font-size: rem(14);
      font-weight: 500;
      line-height: 1.6;
    }

    &.selected {
      a {
        background: $primary;
      }
    }
    &:hover {
      a {
        background: rgba($primary, 0.8);
      }
    }
    &.disabled {
      pointer-events: none;
    }

    //&.previous,
    //&.next{
    //  a{
    //    color: transparent;
    //    background-position: center;
    //    background-repeat: no-repeat;
    //
    //    //chevron-right
    //    //chevron-left
    //  }
    //}
    //
    //&.previous a{
    //  background-image: url(../../img/svg/chevron-left.svg);
    //}
    //&.next a{
    //  background-image: url(../../img/svg/chevron-right.svg);
    //}
  }
}
