.sCatalog {
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    --sPT: #{rem(61px)};
    --sPB: #{rem(50)};
  }
  --sTPB: 0;

  &__top-row {
    --bs-gutter-y: #{rem(24)};

    --bs-gutter-x: #{rem(24)};
    @include media-breakpoint-up(xxl) {
      --bs-gutter-x: #{rem(56)};
    }

    margin-bottom: rem(48);
  }
  .section-title {
    h2 {
      position: relative;
      @include media-breakpoint-up(xxl) {
        left: 6px;
      }
    }
  }
  &__tags-row {
    --bs-gutter-y: #{rem(12)};

    --bs-gutter-x: #{rem(2)};
    @include media-breakpoint-up(sm) {
      --bs-gutter-x: #{rem(10)};
    }
    @include media-breakpoint-up(lg) {
      --bs-gutter-x: #{rem(12)};
    }
    @include media-breakpoint-up(xxl) {
      --bs-gutter-x: #{rem(19)};
      margin-right: -80px;
    }
  }
  &__col {
    &:first-child {
      @include media-breakpoint-up(xxl) {
        --tag-mw: 96px;
      }
    }
    &--splitter {
      @include media-breakpoint-down(md) {
        margin: 0;
      }
      &:after {
        @include media-breakpoint-up(md) {
          content: "";
        }
        display: block;
        width: 1px;
        height: rem(32);
        background: rgba(#efefef, 0.2);
      }
    }
  }
  &__tag {
    min-width: var(--tag-mw, 0);
    cursor: pointer;

    padding: rem(6) rem(12);
    @include media-breakpoint-up(sm) {
      padding: rem(8) rem(16);
    }
    @include media-breakpoint-up(xxl) {
      padding: rem(10) rem(12);
    }
    border-radius: rem(29);
    border: 1px solid transparent;
    text-align: center;

    font-weight: 500;
    font-size: rem(14);
    @include media-breakpoint-up(sm) {
      font-size: rem(16);
    }
    @include media-breakpoint-up(lg) {
      font-size: rem(18);
    }
    line-height: calc(22 / 18);
    color: #efefef;

    &.active {
      border: 1px solid $primary;
    }
  }
  &__btns-row {
    --bs-gutter-x: #{rem(16)};
  }

  &__slider {
    overflow: visible;
    width: 100%;

    &:before,
    &:after {
      content: "";
      position: absolute;
      pointer-events: none;
      z-index: 2;
      top: 0;
      width: calc((100vw - 100%) / 2 + 20px);
      height: 100%;
    }

    &:before {
      left: 100%;
      background: linear-gradient(
        90deg,
        rgba(20, 17, 17, 0) 0%,
        rgba(20, 17, 17, 1) 100%
      );
    }
    &:after {
      right: 100%;
      background: linear-gradient(
        270deg,
        rgba(20, 17, 17, 0) 0%,
        rgba(20, 17, 17, 1) 100%
      );
    }

    height: rem(728);
    @include media-breakpoint-up(lg) {
      height: 728px;
    }
    .swiper-slide {
      @include media-breakpoint-down(sm) {
        width: var(--slide-w, 50vw);
      }

      //height: auto;
      //@include media-breakpoint-up(lg){
      //
      //}
      height: calc((100% - 32px) / 2) !important;
      //width: calc(100%/4 - 32px*3/4);

      display: flex;
      flex-direction: column;
    }
  }
  //
  &__steam-card {
    flex-grow: 1;
    background-color: #262d36 !important;
    border-color: #262d36 !important;
    padding: rem(20);
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: stretch !important;
  }
  &__steam-img {
    display: block;
    padding: 0 rem(7) rem(24);
    img {
      max-width: 100%;
    }
  }
  &__steam-txt {
    font-weight: 800;
    font-size: rem(28);
    line-height: 1.43;
    text-align: center;
    text-transform: capitalize;
    color: $white;
  }
}

.prodCard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  //min-height: 100%;

  //font-size: 20px;
  --name-lh: #{rem(20)};
  --name-pt: #{rem(16)};

  &.fixed-h {
    --img-box-pt: 0;
    --img-box-flex-grow: 1;

    .prodCard__name {
      font-size: rem(13);
      @include media-breakpoint-up(lg) {
        font-size: rem(16);
      }

      min-height: calc(var(--name-lh) * 2 + var(--name-pt));
      @include ell(2);
    }

    //@include media-breakpoint-up(lg){
    //
    //}
  }
  &.muted {
    opacity: 0.5;
  }

  @include mt();
  background: #0b0b0b;
  border-radius: 12px;
  overflow: hidden;
  padding: rem(16);

  border: 2px solid #0b0b0b;
  &:hover {
    border-color: $primary;
    filter: drop-shadow(0px 2px 10px rgba(240, 105, 29, 0.25));
  }

  position: relative;
  &__link {
    display: block;
    position: absolute;
    z-index: 8;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
  &__img-box {
    @extend .accept-ratio-frame;
    padding-top: var(--img-box-pt, 100%);
    flex-grow: var(--img-box-flex-grow, 0);
    border-radius: 8px;
    //margin-bottom: auto;
  }

  &__tags {
    position: absolute;
    display: block;
    top: rem(12);
    left: rem(12);
    width: calc(100% - #{rem(24)});
  }
  &__tags-row {
    --bs-gutter-x: #{rem(4)};
    @include media-breakpoint-up(sm) {
      --bs-gutter-x: #{rem(8)};
    }
    --bs-gutter-y: #{rem(4)};

    & > * {
      display: block;
    }
  }
  &__name {
    padding-top: var(--name-pt, #{rem(16)});
    margin-bottom: auto;
    display: block;
    font-weight: 500;

    line-height: 1.25;
    //line-height: var(--name-lh);
    color: $white;
    //margin-bottom: rem(12);
    word-break: break-word;
  }
  &__price-row {
    padding-top: rem(12);
    --bs-gutter-x: #{rem(12)};
    --bs-gutter-y: #{rem(12)};
  }
  &__price {
    display: block;

    font-weight: 600;
    line-height: calc(20 / 16);
    color: $white;
  }

  //
  &__cart-btn {
    cursor: pointer;
    //position: relative;
    //z-index: 10;
    width: rem(24);
    height: rem(24);
    display: flex;
    align-items: center;
    justify-content: center;

    &.active,
    &:hover {
      path {
        stroke: $primary !important;
      }
    }
  }
  &__no-available {
    line-height: calc(20 / 16);
    font-weight: 600;
  }
}

.tag {
  display: block;
  padding: rem(6) rem(16);
  font-weight: 500;
  font-size: rem(12);
  line-height: calc(15 / 12);
  color: $white;

  backdrop-filter: blur(2px);
  border-radius: rem(20);
}

ul {
  width: 100%;
}
