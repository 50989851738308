.headerBlock {
  cursor: pointer;
  position: relative;
  z-index: 1;

  &__slider {
    .swiper-slide {
      height: auto;
      &.swiper-slide-active {
        --banner-scale: 1;
      }
    }
  }
  &__slide {
    position: relative;
    z-index: 1;
  }
  &__container {
    min-height: calc(100vh - var(--header-h) - #{rem(100)});
    padding: rem(40) calc(#{$container-padding-x}/ 2) rem(220);
    @include media-breakpoint-up(lg) {
      min-height: calc(100vh - var(--header-h) - #{rem(100)});
      padding: rem(40) calc(#{$container-padding-x}/ 2) rem(220);
    }
    @include media-breakpoint-up(xl) {
      min-height: calc(100vh - var(--header-h) - #{rem(100)});
      padding: rem(60) calc(#{$container-padding-x}/ 2) rem(140);
    }
    @include media-breakpoint-up(xxl) {
      min-height: calc(100vh - var(--header-h) - #{rem(100)});
    }

    display: flex;
    flex-direction: column;
  }
  &__bg {
    background: $black;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      transition: 1.5s ease-out;
      transform: scale(var(--banner-scale, 1.15));
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      //object-position: 50% 15%;
      object-position: 50% 70%;
    }
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        75.93deg,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  &__box {
    margin-top: auto;
    @include media-breakpoint-up(md) {
      max-width: 900px;
    }

    @include media-breakpoint-up(xxl) {
      //margin-left: 10px;
    }
  }
  &__title {
    color: $white !important;
    font-weight: 600;
    font-size: rem(40);
    line-height: 1.125;
    margin-bottom: rem(15);
  }
  &__subTitle {
    color: #efefef;
    margin-bottom: rem(40);

    --descr-fs: #{rem(16)};
    --descr-lh: 1.5;

    font-size: var(--descr-fs);
    line-height: var(--descr-lh);
    height: calc(var(--descr-fs) * var(--descr-lh) * 1);
    overflow: hidden;
  }
  &__btn-row {
    --bs-gutter-x: #{rem(32)};
  }
  &__col {
    &--price {
      display: flex;
      align-items: baseline;

      font-weight: 600;
    }
  }
  &__buy-btn {
    @extend .btn;
    @extend .btn-primary;

    font-weight: 600;
    padding: rem(12) rem(24);
    min-width: rem(185);
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
  }
  &__price {
    color: $white !important;
    font-size: rem(24);
    line-height: calc(29 / 24);
    margin-right: 4px;
  }
  &__currency {
    color: $white !important;
    line-height: calc(20 / 16);
    text-transform: uppercase;
  }

  //
  &__overlay {
    position: absolute;
    bottom: rem(-79);
    left: 0;
    width: 100%;
  }
  &__thumb-wrap {
    @include media-breakpoint-up(lg) {
      max-width: 560px;
      margin-left: auto;
    }

    position: relative;
    z-index: 1;

    .swiper-wrapper {
      margin-bottom: rem(22);
    }
    .swiper-slide {
      width: calc(100% / 3 - 16px * 2 / 3);

      &.swiper-slide-thumb-active {
        &:after {
          opacity: 1;
        }
      }

      &:after {
        @include mt();
        content: "";
        pointer-events: none;
        position: absolute;
        z-index: 2;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border-radius: rem(8);
        border: 2px solid $primary;
        opacity: 0;
      }
    }
  }
  &__thumb-slide {
    cursor: pointer;
    @extend .accept-ratio-frame;
    padding-top: 100%;
    border-radius: rem(8);

    cursor: pointer;
  }
  //
  &__thumb-btn {
    @extend .swiper-btn;
    position: absolute;
    top: calc(50% - #{rem(18)});
    z-index: 1;
    transform: translateY(-50%) translateX(-50%);

    &.prev {
      left: 0;
    }
    &.next {
      left: 100%;
    }
  }
}
