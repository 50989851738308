.sOrder {
  padding: 130px 0;
  min-height: 94vh;
  height: 100%;

  @media (max-width: 992px) {
    padding: 100px 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 325px;
    margin-bottom: 75px;

    @media (max-width: 992px) {
      text-align: center;
      margin-bottom: 50px;
      max-width: 100%;
      width: 100%;
    }

    @media (max-width: 456px) {
      font-size: 22px;
    }
  }

  &__descr {
    font-size: 20px;

    @media (max-width: 456px) {
      font-size: 18px;
    }
  }

  &__inner {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 60px;

    @media (max-width: 992px) {
      flex-direction: column;
      align-items: center;
      gap: 50px;
    }
  }

  &__bill-wrapper {
    background: #0b0b0b;
    border-radius: 15px;
    padding: 20px;
    overflow: hidden;
    max-width: 500px;
    width: 100%;
    border: 2px solid $primary;
    filter: drop-shadow(0px 2px 10px rgba(240, 105, 29, 0.25));

    @media (max-width: 992px) {
      max-width: 100%;
    }

    @media (max-width: 456px) {
      padding: 15px;
    }
  }

  &__bill-title {
    font-size: 26px;
    margin-bottom: 20px;
  }

  &__bill-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 18px;

    @media (max-width: 456px) {
      font-size: 16px;
    }
  }

  &__bill-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(240, 106, 29, 0.446);
  }

  &__info-items {
    margin-bottom: 35px;
  }

  &__info-item {
    &:first-child {
      font-weight: bold;
    }
  }
}
