.sResent {
  overflow: hidden;
  @include media-breakpoint-up(xxl) {
    --sPT: #{rem(50px)};
    --sPB: #{rem(50)};
    --sTPB: #{rem(49)};
  }

  .section-title {
    @include media-breakpoint-up(xxl) {
      padding-left: 6px;
    }
  }
  &__slider {
    @include media-breakpoint-down(lg) {
      overflow: visible;
    }
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    .swiper-wrapper {
      order: 1;
      margin-bottom: 22px;
    }

    .swiper-slide {
      height: auto;

      display: flex;
      flex-direction: column;
      align-items: stretch;

      width: var(--slide-w, 50vw);
      @include media-breakpoint-up(sm) {
        width: calc(100% / 2 - 32px * 1 / 2);
      }
      @include media-breakpoint-up(lg) {
        width: calc(100% / 3 - 32px * 2 / 3);
      }
      @include media-breakpoint-up(xl) {
        width: calc(100% / 4 - 32px * 3 / 4);
      }
    }
    .swiper-pagination {
      @include media-breakpoint-down(sm) {
        display: none;
      }
      @include media-breakpoint-up(md) {
        margin-right: 10px;
        width: auto;
        order: 9;
      }
      //justify-content: flex-start;
    }
  }
  .swiper-tp-btn {
    &.prev {
      margin-right: 10px;
      order: 8;
    }
    &.next {
      order: 10;
    }
  }
}
