.sGame {
  @include media-breakpoint-up(xxl) {
    --sPT: #{rem(50px)};
    --sPB: #{rem(50)};
    --sTPB: #{rem(37)};
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: var(--sPB);
  }

  background: rgba(0, 0, 0, 0.9);

  &__content {
    @include media-breakpoint-up(xl) {
      max-width: rem(940);
      margin: 0 auto;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba($white, 0.3);
      padding-bottom: rem(20);
      margin-bottom: rem(20);
    }
  }
}
