.cSlider {
  overflow: hidden;
  &__slider {
    @include media-breakpoint-down(lg) {
      position: relative;
      width: 100vw;
      left: calc((100% - 100vw) / 2);
    }

    //margin-bottom: rem(40);
    //@include media-breakpoint-up(xl){
    //  margin-bottom: rem(80);
    //}

    .swiper-pagination {
      position: absolute;
      z-index: 2;
      right: var(--pagin-right);

      justify-content: flex-start;
      --pagin-right: #{rem(16)};
      @include media-breakpoint-up(sm) {
        justify-content: center;
        --pagin-right: #{rem(48)};
      }
      width: calc(100% - var(--pagin-right) * 2);
      @include media-breakpoint-up(lg) {
        width: auto;
        max-width: calc(100% - var(--pagin-right) * 2);
      }

      bottom: rem(24);
      @include media-breakpoint-up(xl) {
        bottom: rem(35);
        --pagin-right: #{rem(103)};
      }
    }

    .swiper-slide {
      height: auto;
      display: flex;
      flex-direction: column;
    }
  }
  &__wrap {
    min-height: rem(325);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    z-index: 1;
  }
  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: rem(60) calc(#{$container-padding-x}/ 2) rem(60);
    @include media-breakpoint-up(lg) {
      padding: rem(60) rem(24);
    }
    @include media-breakpoint-up(xl) {
      padding: rem(48px) rem(48) rem(62);
    }
  }

  &__bg {
    //background: $black;

    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include media-breakpoint-up(lg) {
        border-radius: rem(13);
      }
    }

    &:before {
      @include media-breakpoint-up(lg) {
        border-radius: rem(12);
      }
      content: "";
      position: absolute;
      overflow: hidden;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $black;
    }
    &:after {
      @include media-breakpoint-up(lg) {
        border-radius: rem(12);
      }
      content: "";
      position: absolute;
      z-index: 2;
      overflow: hidden;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-width: 1000px;
      background: linear-gradient(
        75.93deg,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  &__title {
    @extend .h2;
    //font-size: rem(40);
    line-height: 1.3;
    max-width: rem(500);

    margin-bottom: rem(24);
  }
  &__btn {
    margin-top: auto;
    @extend .btn;
    @extend .btn-primary;

    text-transform: uppercase;

    background: $primary;
    min-width: rem(185);
  }
}
