.mobMenu {
  @include mt();
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  pointer-events: none;
  opacity: 0;
  background-color: rgba($black, 0);
  backdrop-filter: blur(0px);

  //
  --inner-bg: #{rgba(lighten($gray-990, 2), 1)};
  --outer-bg: #{rgba($black, 0.3)};
  &.active {
    pointer-events: auto;
    opacity: 1;

    background-color: var(--inner-bg);
    @include media-breakpoint-up(sm) {
      background-color: var(--outer-bg);
    }

    backdrop-filter: blur(1.5px);

    //
    --inner-translate-x: 0;
  }

  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  &__inner {
    @include mt();
    transform: translateX(var(--inner-translate-x, 105%));
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;

    width: 100%;
    padding-right: calc(#{$container-padding-x} / 2);
    padding-left: calc(#{$container-padding-x} / 2);
    @include media-breakpoint-up(sm) {
      background-color: var(--inner-bg);
      width: calc(1 * (100vw - 100%) / 2 + #{rem(350)});
      margin-left: auto;

      padding-right: calc(1 * (100vw - 100%) / 2);
      padding-left: 0;
    }
    @include media-breakpoint-up(md) {
      width: calc(1 * (100vw - 100%) / 2 + 350px);
    }
    margin-right: calc(-1 * (100vw - 100%) / 2);

    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  //
  &__content {
    padding: calc(var(--header-h) + #{rem(20)}) 0 rem(32);
    @include media-breakpoint-up(sm) {
      padding: calc(var(--header-h) + #{rem(20)}) 0 rem(32) rem(16);
      //margin: 0;
    }

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  //
  .menu {
    margin-bottom: rem(30);
    &__row {
      --bs-gutter-x: #{rem(16)};
      @include media-breakpoint-up(xxl) {
        --bs-gutter-x: #{rem(24)};
      }

      --bs-gutter-y: #{rem(24)};
    }
    &__link {
      display: flex;
      align-items: center;

      font-weight: 500;
      font-size: rem(18);
      line-height: 1;
      color: $white;

      //?
      //img{
      //  width: rem(24);
      //  margin-right: rem(8);
      //}

      &.active {
        color: $primary;
        --def-img-o: 0;
        --active-img-o: 1;
      }
    }
    &__link-img {
      width: rem(24);
      height: rem(24);
      margin-right: rem(8);

      position: relative;
      img {
        @include mt();
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: auto;

        &:first-child {
          opacity: var(--def-img-o, 1);
        }
        &:last-child {
          opacity: var(--active-img-o, 0);
        }
      }
    }
  }

  //
  //currency
  .currency {
    margin-top: auto;
    position: relative;
    z-index: 1;

    &__row {
      display: none;
    }
    &__dd-title {
      font-weight: 600;
      font-size: rem(18);
      line-height: calc(22 / 12);
      letter-spacing: rem(-0.128);
    }
    &__box {
      margin-bottom: rem(12);
      background: rgba(35, 55, 55, 0.1);
      border-radius: rem(8);
      padding: rem(4);
    }
    &__b-row {
      --bs-gutter-x: #{rem(4)};
    }
    &__btn {
      @include mt();
      @extend .btn;
      @extend .btn-primary;
      border: none !important;
      padding: rem(4) rem(12);
      min-height: rem(32);

      display: flex;
      align-items: center;
      justify-content: center;

      background: transparent !important;

      img {
        width: rem(16);
        margin-right: rem(4);
      }

      font-size: 14px;
      letter-spacing: rem(-0.128);
      color: $secondary !important;
      &:hover {
        color: $white !important;
        background: rgba($primary, 0.1) !important;
      }
      &.active {
        color: $white !important;
        background: $primary !important;
      }
    }
  }

  //
  .search {
    &__inp-wrap {
      position: relative;
      z-index: 1;
    }
    &__input {
      width: 100%;
      height: rem(36);
      //width: 100%;
      padding-right: rem(36);

      border-radius: rem(60);
      font-size: rem(16);
      line-height: 1;
      background: #2d2a2a;
      border: none;
    }
    &__inp-icon {
      @include mt();
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: rem(36);
      height: rem(36);
      border-radius: 50%;
      padding: rem(8);

      display: flex;
      align-items: center;
      justify-content: center;

      & > * {
        max-width: 100%;
      }

      background: rgba($black, 0.1);
      path {
        stroke: #7a7a7a !important;
      }

      &:hover {
        background: $primary;
        path {
          stroke: white !important;
        }
      }
    }
  }
}
