.footer{

  padding: rem(48) 0;
  background: #0B0B0B;

  &__row{
    --bs-gutter-y: #{rem(8)};

    margin-bottom: rem(36);
    @include media-breakpoint-up(lg){
      margin-bottom: rem(8);
    }

    --bs-gutter-x: #{rem(24)};
    @include media-breakpoint-up(xl){
      --bs-gutter-x: #{rem(32)};
    }
  }
  &__col{
    --left-col-w: #{rem(200)};

    &--left{
      @include media-breakpoint-up(lg){
        width: calc(var(--left-col-w) + var(--bs-gutter-x)) !important;
      }
    }
    &--middle{
      @include media-breakpoint-up(lg){
        width: calc(100% - (var(--left-col-w) + var(--bs-gutter-x))*2) !important;
      }
    }
    &--right{
      @include media-breakpoint-up(lg){
        width: calc(var(--left-col-w) + var(--bs-gutter-x)) !important;
      }
    }
  }
  &__logo{
    display: flex;
    align-items: center;
    img{
      width: rem(69);
      margin-right: rem(8);
    }
  }
  &__logo-txt{
    font-weight: 500;
    font-size: rem(20);
    line-height: 1.3;
    color: $primary;
  }
  &__menu{

    @include media-breakpoint-up(sm){
      display: flex;
      align-items: center;
    }
    @include media-breakpoint-up(lg){
      justify-content: center;
    }

    a{
      white-space: nowrap;
      display: block;
      color: $white;
      margin-right: var(--bs-gutter-x);

      font-size: rem(14);
      @include media-breakpoint-up(xl){
        font-size: rem(16);
      }
      &:last-child{
        margin-right: 0;
      }
      &:hover{
        color: $primary;
      }
    }
  }
  &__soc{
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg){
      justify-content: flex-end;
    }
    a{
      width: rem(24);
      height: rem(24);

      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: var(--bs-gutter-x);
      &:last-child{
        margin-right: 0;
      }

      & > *{
        width: 100%;
      }
    }
  }
  &__all-rights{
    line-height: 1.5;
    color: #EFEFEF;
  }
}