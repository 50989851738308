.WhyUs {
  @include media-breakpoint-up(xxl) {
    --sPT: #{rem(50)};
    --sPB: #{rem(50)};
    --sTPB: #{rem(40)};
  }

  &__row {
    --bs-gutter-x: #{rem(12)};
    --bs-gutter-y: #{rem(12)};
    @include media-breakpoint-up(xl) {
      --bs-gutter-x: #{rem(32)};
      --bs-gutter-y: #{rem(32)};
    }
  }
  &__item {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    background: #0b0b0b;
    border-radius: rem(12);

    padding: rem(24) rem(16) rem(20);
    @include media-breakpoint-up(sm) {
      padding: rem(32) rem(20) rem(24);
    }
    @include media-breakpoint-up(xl) {
      padding: rem(45) rem(24) rem(32);
    }
  }
  &__img {
    width: rem(132);
    max-width: 100%;

    margin: 0 auto rem(20);
    @include media-breakpoint-up(sm) {
      margin: 0 auto rem(32);
    }
    @include media-breakpoint-up(xl) {
      margin: 0 auto rem(45);
    }

    img {
      width: 100%;
    }
  }
  &__title {
    font-weight: 500;
    font-size: rem(20);
    color: $white;
  }
}
