.cHead {
  overflow: hidden;
  position: sticky;
  top: var(--header-h);
  z-index: 100;
  left: 0;

  &__header {
    background: #2d2a2a;

    padding: rem(16) 0;
    @include media-breakpoint-up(sm) {
      padding: rem(20) 0;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: rem(60);
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(100);
    }
  }

  &__slider-wrap {
    display: flex;
    justify-content: center;
  }
  &__slider {
    max-width: 100%;
    overflow: visible !important;
    .swiper-slide {
      width: auto;
    }
  }
  &__splitter {
    border-right: 1px solid $gray-600;
    height: rem(20);
  }
  &__h-link {
    @include mt();
    cursor: pointer;
    font-weight: 500;

    font-size: rem(12);
    @include media-breakpoint-up(sm) {
      font-size: rem(14);
    }
    @include media-breakpoint-up(xl) {
      font-size: rem(16);
    }

    line-height: calc(17 / 14);
    color: $white;

    &:hover,
    &.active {
      color: $primary;
    }
  }
}
