@use "sass:math";

.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-basket {
	font-size:math.div(24,10)*1rem;
	width: math.div(22,24)*1em;
}
.icon-check {
	font-size:math.div(27,10)*1rem;
	width: math.div(27,27)*1em;
}
.icon-fb {
	font-size:math.div(24,10)*1rem;
	width: math.div(24,24)*1em;
}
.icon-instagram {
	font-size:math.div(24,10)*1rem;
	width: math.div(24,24)*1em;
}
.icon-telegram {
	font-size:math.div(16,10)*1rem;
	width: math.div(16,16)*1em;
}
.icon-viber {
	font-size:math.div(16,10)*1rem;
	width: math.div(16,16)*1em;
}
.icon-vk {
	font-size:math.div(24,10)*1rem;
	width: math.div(24,24)*1em;
}
.icon-whatsapp {
	font-size:math.div(16,10)*1rem;
	width: math.div(16,16)*1em;
}
