.main__payment {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      display: flex;
      justify-content: center;

      h1 {
        font-size: 72px;
        margin-bottom: 1.5rem;
      }

      img {
        width: 72px;
        height: 72px;
        margin: 0 1.5rem;
      }
    }

    h2 {
      margin-bottom: 4rem;
      font-size: 24px;
      font-weight: 500;
      color: #f0f0f0;
    }

    .button {
      padding: 12px 24px;
      width: fit-content;
      background-color: #F0691D;
      border-radius: 4px;
      color: white;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        background-color: #F2803F;
      }
    }
  }
}
