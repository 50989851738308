.sFresh {
  --sPB: #{rem(90)};
  @include media-breakpoint-up(xxl) {
    --sPT: #{rem(50px)};
    --sPB: #{rem(100)};
  }
  --sTPB: #{rem(0)};

  //background: #0B0B0B;
  @include media-breakpoint-up(xl) {
    background: transparent;
  }
  &__box {
    @include media-breakpoint-up(xl) {
      background: #0b0b0b;
      border-radius: rem(20);
      padding: rem(75) rem(60) 0;
    }
  }
  &__box-inner {
    @extend .custom-scroll;

    @include media-breakpoint-up(xl) {
      overflow-y: auto;
      height: rem(720);
      max-height: calc(100vh - var(--header-h) - #{rem(220)});
      margin-right: rem(-20);
      padding-right: rem(20);
      padding-bottom: rem(20);
    }
  }
  &__row {
    --bs-gutter-y: #{rem(24)};

    --bs-gutter-x: #{rem(12)};
    @include media-breakpoint-up(sm) {
      --bs-gutter-x: #{rem(24)};
    }
    margin-bottom: rem(24);
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(35);
      --bs-gutter-x: #{rem(32)};
    }
  }
  &__btn {
    @include mt();
    width: rem(30);
    height: rem(30);
    @include media-breakpoint-up(sm) {
      width: rem(40);
      height: rem(40);
    }
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transform: scale(1.1);
    }
    & > * {
      max-width: 100%;
    }
  }

  &__item {
    overflow: hidden;
    font-family: $mono;
    margin-bottom: rem(24);
    &:last-child {
      margin-bottom: 0;
    }

    background: #0b0b0b;
    @include media-breakpoint-up(lg) {
      background: #141111;
    }
    border: 1.5px solid #2f2f2f;
    border-radius: rem(12);

    padding: rem(24) rem(16) rem(29);
    @include media-breakpoint-up(sm) {
      padding: rem(24) rem(32) rem(29);
    }
  }
  &__i-row {
    --bs-gutter-x: #{rem(32)};
    --bs-gutter-y: #{rem(20)};
  }
  &__i-col {
    --img-col-w: 36.7%;

    &--img {
      @include media-breakpoint-up(md) {
        width: var(--img-col-w) !important;
      }
    }
    &--content {
      @include media-breakpoint-up(md) {
        width: calc(100% - var(--img-col-w)) !important;
      }
      flex-grow: 1;
    }
  }
  &__i-img {
    @extend .accept-ratio-frame;
    padding-top: calc(208 / 359 * 100%);
    border-radius: rem(8);
  }
  &__author-row {
    --bs-gutter-x: #{rem(8)};
    --bs-gutter-y: #{rem(8)};

    margin-bottom: rem(4);
  }
  &__avatar {
    width: rem(32);
    height: rem(32);
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__name {
    font-weight: 500;
    font-size: rem(20);
  }
  &__date {
    font-weight: 500;
    font-size: rem(12);
    color: #7b7b7b;
    margin-bottom: rem(8);
  }
  &__content {
    margin-bottom: rem(8);
    line-height: 19px;

    &.clapTxt {
      @include ell(6);
    }
  }
  &__view-more {
    display: inline-block;
    cursor: pointer;
    font-weight: 500;
    color: $primary;
  }
}
