.sHow {
  @include media-breakpoint-up(xxl) {
    --sPT: #{rem(50)};
    --sPB: #{rem(75)};
    --sTPB: #{rem(48)};
  }
  &__item {
    padding-bottom: rem(24);
    margin-bottom: rem(24);
    @include media-breakpoint-up(md) {
      padding-bottom: rem(31);
      margin-bottom: rem(24);
    }

    border-bottom: 1px solid #eaecf0;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    --bs-gutter-x: #{rem(24)};
    --ball-w: 22px;
  }
  &__head {
    cursor: pointer;
    --ball-color: #{$white};
    &:hover {
      --ball-color: #{$primary};
    }
  }
  &__title {
    @include mt();
    font-family: $mono;
    font-weight: 500;
    font-size: rem(18);
    line-height: calc(28 / 18);
    color: var(--ball-color);
  }
  &__ball {
    @include mt();
    width: var(--ball-w);
    height: var(--ball-w);
    border-radius: 50%;
    border: 2px solid var(--ball-color);
    position: relative;

    &:before,
    &:after {
      @include mt();
      content: "";
      background: var(--ball-color);
      border-radius: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

    &:before {
      width: rem(10);
      height: 2px;
    }
    &:after {
      height: rem(10);
      width: 2px;
    }

    &.active {
      &:after {
        opacity: 0;
      }
    }
  }
  &__content-inner {
    font-family: $mono;

    padding-top: rem(16);
    @include media-breakpoint-up(lg) {
      padding-top: rem(8);
    }
    @include media-breakpoint-up(sm) {
      max-width: calc(100% - var(--bs-gutter-x) - var(--ball-w));
    }
    line-height: 1.5;
    color: #7b7b7b;
  }
}
