.sInfo {
  @include media-breakpoint-up(xl) {
    --sTPB: #{rem(64)};
  }

  .content {
    margin-bottom: rem(48);

    ol {
      padding-left: 0;
      list-style: none;
      counter-reset: num 0;

      li {
        &:before {
          display: inline-block;
          min-width: rem(20);
          margin-right: rem(4);
          content: counter(num) ". ";
          counter-increment: num;
        }
      }
    }

    img {
      max-width: 100%;
      display: block;
      margin-bottom: rem(20);
      border-radius: rem(8);
    }
  }
  .section-title {
    h1 {
      font-size: rem(40);
      line-height: calc(49 / 40);
    }
  }
  &__list {
    counter-reset: num;
    list-style: none;
    padding: 0;
    margin: 0 0 rem(32);

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__list-item {
    margin-bottom: rem(36);
    @include media-breakpoint-up(xxl) {
      margin-bottom: rem(56);
    }
  }
  &__txt {
    position: relative;
    z-index: 1;

    --ball-w: #{rem(36)};
    padding-left: 0;
    @include media-breakpoint-up(sm) {
      --ball-w: #{rem(42)};
      padding-left: calc(var(--ball-w) + #{rem(24)});
    }

    margin-bottom: rem(24);
    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: counter(num);
      counter-increment: num;

      margin-bottom: rem(12);
      @include media-breakpoint-up(sm) {
        position: absolute;
        top: 0;
        left: 0;
        margin-bottom: 0;
      }
      padding: rem(8);
      width: var(--ball-w);
      height: var(--ball-w);
      border-radius: rem(24);

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: $primary;

      font-weight: 500;
      font-size: rem(18);
      line-height: calc(22 / 18);
    }
  }
  &__title {
    font-weight: 600;
    font-size: rem(24);
    margin-bottom: rem(8);
  }
  &__descr {
    font-size: rem(20);

    img {
      max-width: 100%;
      margin: rem(20) 0;
    }
  }
  &__img {
    margin-bottom: rem(24);
    &:last-child {
      margin-bottom: 0;
    }

    img {
      max-width: 100%;
      height: auto;
      border-radius: rem(8);
    }
  }
}
